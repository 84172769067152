<template>
    <div id="privacypolicy" class="frontpage">
        <HeaderTitle :title="'Kebijakan Privasi'" :subtitle="''" />
        <section>
            <div class="container py-5">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-12 col-md-8">
                        <h2 class="fs-16"><b>KEBIJAKAN PRIVASI</b></h2><br>
                        Dengan menggunakan layanan kami, Anda memercayakan informasi Anda kepada kami. Kami paham bahwa melindungi informasi Anda dan memberikan kontrol kepada Anda adalah tanggung jawab yang besar dan memerlukan kerja keras. <br><br>
                        Halaman ini memberi tahu Anda tentang kebijakan kami mengenai pengumpulan, penggunaan, dan pengungkapan data pribadi saat Anda menggunakan Layanan kami dan pilihan yang telah Anda kaitkan dengan data itu. <br><br>
                        Kami menggunakan data Anda untuk menyediakan dan meningkatkan Layanan. Dengan menggunakan Layanan, Anda menyetujui pengumpulan dan penggunaan informasi sesuai dengan kebijakan ini. Kecuali ditentukan lain dalam Kebijakan Privasi ini, istilah yang digunakan dalam Kebijakan Privasi ini memiliki arti yang sama seperti dalam Syarat dan Ketentuan kami.<br><br>
                        Pengumpulan Dan Penggunaan Informasi :<br><br>
                        Kami mengumpulkan beberapa jenis informasi yang berbeda untuk berbagai keperluan untuk menyediakan dan meningkatkan Layanan kami kepada Anda.<br><br>
                        Jenis Data yang Dikumpulkan :<br><br>
                        <h2 class="fs-16"><b>1. Data Pribadi</b></h2><br>
                        Saat menggunakan Layanan kami, kami mungkin meminta Anda untuk memberikan kami informasi pengenal pribadi tertentu yang dapat digunakan untuk menghubungi atau mengidentifikasi Anda (“Data Pribadi”). Informasi yang dapat diidentifikasi secara pribadi dapat mencakup, tetapi tidak terbatas pada:<br><br>
                        <ul class="bullet-style">
                            <li>Alamat email</li>
                            <li>Nama depan dan nama belakang</li>
                            <li>Nomor telepon</li>
                            <li>Alamat, Negara Bagian, Provinsi, ZIP / Kode pos, Kota</li>
                            <li>Cookie dan Data Penggunaan</li>
                            <li>Informasi Akademik yang berkaitan dengan kemahasiswaan dan karyawan</li>
                        </ul><br><br>
                        <h2 class="fs-16"><b>2. Data Penggunaan</b></h2><br>
                        Saat Anda mengakses Layanan dengan atau melalui perangkat seluler, kami dapat mengumpulkan informasi tertentu secara otomatis, termasuk, namun tidak terbatas pada, jenis perangkat seluler yang Anda gunakan, ID unik perangkat seluler Anda, alamat IP perangkat seluler Anda, ponsel Anda sistem operasi, jenis browser Internet seluler yang Anda gunakan, pengidentifikasi perangkat unik dan data diagnostik lainnya (“Data Penggunaan”). <br><br>
                        <h2 class="fs-16"><b>3. Pelacakan & Data Cookie</b></h2><br>
                        Kami menggunakan cookie dan teknologi pelacakan serupa untuk melacak aktivitas di Layanan kami dan menyimpan informasi tertentu.<br><br>
                        Cookie adalah file dengan sedikit data yang dapat menyertakan pengidentifikasi unik anonim. Cookie dikirim ke browser Anda dari situs web dan disimpan di perangkat Anda. Teknologi pelacakan yang juga digunakan adalah suar, tag, dan skrip untuk mengumpulkan dan melacak informasi dan untuk meningkatkan dan menganalisis Layanan kami.<br><br>
                        Anda dapat menginstruksikan browser Anda untuk menolak semua cookie atau untuk menunjukkan kapan cookie dikirim. Namun, jika Anda tidak menerima cookie, Anda mungkin tidak dapat menggunakan sebagian Layanan kami.<br><br>
                        Contoh Cookie yang kami gunakan:<br><br>
                        <b>Cookie Sesi</b> : Kami menggunakan Cookie Sesi untuk mengoperasikan Layanan kami.<br>
                        <b>Cookie Pilihan</b> : Kami menggunakan Cookie Preferensi untuk mengingat preferensi Anda dan berbagai pengaturan.<br>
                        <b>Cookie Keamanan</b> : Kami menggunakan Cookie Keamanan untuk tujuan keamanan.<br><br>
                        <h2 class="fs-16"><b>4. Penggunaan Data</b></h2><br>
                        Infinity menggunakan data yang dikumpulkan untuk berbagai tujuan untuk : <br><br>
                        <ul class="bullet-style">
                            <li>Menyediakan dan memelihara Layanan</li>
                            <li>Memberitahukan Anda tentang perubahan didalam pelayanan kami</li>
                            <li>Memungkinkan Anda berpartisipasi dalam fitur interaktif Layanan kami ketika Anda memilih untuk melakukannya</li>
                            <li>Memberikan layanan dan dukungan pelanggan</li>
                            <li>Memberikan analisis atau informasi berharga sehingga kami dapat meningkatkan Layanan</li>
                            <li>Memantau penggunaan Layanan</li>
                            <li>Mendeteksi, mencegah dan mengatasi masalah teknis</li>
                        </ul><br>
                        <h2 class="fs-16"><b>5. Transfer Data</b></h2><br>
                        Informasi Anda, termasuk Data Pribadi, dapat ditransfer ke – dan dipelihara di – komputer yang berlokasi di luar negara bagian, provinsi, negara atau yurisdiksi pemerintah lainnya di mana undang-undang perlindungan data mungkin berbeda dari yang ada di yurisdiksi Anda.<br><br>
                        Jika Anda berada di luar Indonesia dan memilih untuk memberikan informasi kepada kami, harap perhatikan bahwa kami mentransfer data, termasuk Data Pribadi, ke Indonesia dan memprosesnya di sana.<br><br>
                        Persetujuan Anda untuk Kebijakan Privasi ini diikuti dengan pengajuan informasi tersebut merupakan persetujuan Anda untuk transfer tersebut.<br><br>
                        Infinity akan mengambil semua langkah yang wajar diperlukan untuk memastikan bahwa data Anda diperlakukan dengan aman dan sesuai dengan Kebijakan Privasi ini dan tidak ada transfer Data Pribadi Anda yang akan terjadi pada suatu organisasi atau negara kecuali jika ada kontrol yang memadai di tempat termasuk keamanan Anda data dan informasi pribadi lainnya.<br><br>
                        <h2 class="fs-16"><b>6. Keterbukaan Data</b></h2><br>
                        Persyaratan resmi<br><br>
                        Infinity dapat mengungkapkan Data Pribadi Anda dengan itikad baik bahwa tindakan tersebut diperlukan untuk :<br><br>
                        <ul class="bullet-style">
                            <li>Mematuhi kewajiban hukum</li>
                            <li>Melindungi dan mempertahankan hak atau properti Infinity</li>
                            <li>Mencegah atau menyelidiki kesalahan yang mungkin terjadi sehubungan dengan Layanan</li>
                            <li>Melindungi keamanan pribadi pengguna Layanan atau publik</li>
                            <li>Melindungi terhadap tanggung jawab hukum</li>
                        </ul><br>
                        <h2 class="fs-16"><b>7. Keamanan Data</b></h2><br>
                        Keamanan data Anda penting bagi kami, tetapi ingat bahwa tidak ada metode transmisi melalui Internet, atau metode penyimpanan elektronik yang 100% aman. Meskipun kami berusaha untuk menggunakan cara yang dapat diterima secara komersial untuk melindungi Data Pribadi Anda, kami tidak dapat menjamin keamanan mutlaknya. <br><br>
                        <h2 class="fs-16"><b>8. Penyedia jasa</b></h2><br>
                        Kami dapat mempekerjakan perusahaan pihak ketiga dan individu untuk memfasilitasi Layanan kami (“Penyedia Layanan”), untuk menyediakan Layanan atas nama kami, untuk melakukan layanan terkait Layanan atau untuk membantu kami dalam menganalisis bagaimana Layanan kami digunakan.<br><br>
                        Pihak ketiga ini memiliki akses ke Data Pribadi Anda hanya untuk melakukan tugas-tugas ini atas nama kami dan berkewajiban untuk tidak mengungkapkan atau menggunakannya untuk tujuan lain apa pun.<br><br>
                        <h2 class="fs-16"><b>9. Analisis</b></h2><br>
                        Kami dapat menggunakan Penyedia Layanan pihak ketiga untuk memantau dan menganalisis penggunaan Layanan kami. <br><br>
                        <h2 class="fs-16"><b>10. Google Analytics</b></h2><br>
                        Google Analytics adalah layanan analisis web yang ditawarkan oleh Google yang melacak dan melaporkan lalu lintas situs web. Google menggunakan data yang dikumpulkan untuk melacak dan memantau penggunaan Layanan kami. Data ini dibagikan dengan layanan Google lainnya. Google dapat menggunakan data yang dikumpulkan untuk mengontekstualisasikan dan mempersonalisasikan iklan dari jaringan iklannya sendiri.<br><br>
                        Anda dapat memilih keluar dari fitur Google Analytics tertentu melalui pengaturan perangkat seluler Anda, seperti pengaturan iklan perangkat Anda atau dengan mengikuti instruksi yang diberikan oleh Google dalam Kebijakan Privasi mereka: <a href="https://policies.google.com/privacy?hl=id" target="_blank">https://policies.google.com/privacy?hl=id</a><br><br>
                        Untuk informasi lebih lanjut tentang praktik privasi Google, silakan kunjungi halaman web Google Privacy & Terms: <a href="https://policies.google.com/privacy?hl=id" target="_blank">https://policies.google.com/privacy?hl=id</a><br><br>
                        <h2 class="fs-16"><b>11. Tautan ke Situs Lain</b></h2><br>
                        Layanan kami dapat berisi tautan ke situs lain yang tidak dioperasikan oleh kami. Jika Anda mengklik tautan pihak ketiga, Anda akan diarahkan ke situs pihak ketiga itu. Kami sangat menyarankan Anda untuk meninjau Kebijakan Privasi setiap situs yang Anda kunjungi.<br><br>
                        Kami tidak memiliki kendali atas dan tidak bertanggung jawab atas konten, kebijakan privasi, atau praktik situs atau layanan pihak ketiga mana pun.<br><br>
                        <h2 class="fs-16"><b>12. Privasi anak-anak</b></h2><br>
                        Layanan kami tidak membahas siapa pun di bawah usia 18 (“Anak-anak”).<br><br>
                        Kami tidak secara sadar mengumpulkan informasi yang dapat diidentifikasi secara pribadi dari siapa pun yang berusia di bawah 18 tahun. Jika Anda adalah orang tua atau wali dan Anda mengetahui bahwa Anak-anak Anda telah memberikan kepada kami Data Pribadi, silakan hubungi kami. Jika kami mengetahui bahwa kami telah mengumpulkan Data Pribadi dari anak-anak tanpa verifikasi izin orang tua, kami mengambil langkah-langkah untuk menghapus informasi itu dari server kami.<br><br>
                        <h2 class="fs-16"><b>13. Perubahan Terhadap Kebijakan Privasi Ini</b></h2><br>
                        Kami dapat memperbarui Kebijakan Privasi kami dari waktu ke waktu. Kami akan memberi tahu Anda tentang segala perubahan dengan memposting Kebijakan Privasi baru di halaman ini.<br><br>
                        Kami akan memberi tahu Anda melalui email dan / atau pemberitahuan penting pada Layanan kami, sebelum perubahan menjadi efektif dan memperbarui “tanggal efektif” di bagian atas Kebijakan Privasi ini.<br><br>
                        Anda disarankan untuk meninjau Kebijakan Privasi ini secara berkala untuk setiap perubahan. Perubahan pada Kebijakan Privasi ini efektif ketika diposkan pada halaman ini.<br><br>
                        <h2 class="fs-16"><b>14. Hubungi kami</b></h2><br>
                        Jika Anda memiliki pertanyaan tentang Kebijakan Privasi ini, silakan hubungi kami:<br><br>
                        INFINITY<br><br>
                        Program Studi Manajemen Fakultas Bisnis dan Ekonomika Universitas Surabaya<br><br>
                        No HP:<br>
                        Alamat:<br>
                        Penanggung jawab:<br>
                        Email: <br><br>
                        <b>*Pembaharuan Kebijakan Privasi 01/01/2019</b>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import HeaderTitle from '@/components/HeaderTitle'

    export default {
        name: 'PrivacyPolicy',
        components: {
            HeaderTitle
        },
        data () {
            return {}
        }
    }
</script>
